<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <a-tooltip placement="top">
          <template slot="title"><span>Add Fuel Type</span></template>
          <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-fuel-type-modal @click="$bvModal.show('add-update-fuel-type-modal'), resetForm()">
            <i class="fa fa-plus"/> Add Fuel Type
          </a>
        </a-tooltip>
        <table class="table table-sm table-bordered text-center" id="fuel_type_list">
          <thead>
          <tr>
            <th>#</th>
            <th>Fuel Type</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(fuel_type, index) in fuel_types" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ fuel_type.fuel_type_desc }}</td>
            <td>
              <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(fuel_type.id), $bvModal.show('add-update-fuel-type-modal')"><i class="fe fe-edit mr-1"></i>Update</a>
              <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(fuel_type.id)"><i class="fe fe-trash mr-1"></i>Delete</a>
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="fuel_types.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
    <add-update-fuel-type
      :fuel_type="fuel_type"
      :btnEdit="btnEdit"
      @getFuelTypes="getFuelTypes"
      @resetForm="resetForm"
      ref="addUpdateFuelType">
    </add-update-fuel-type>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateFuelType from '@/views/fuelType/modal/addUpdateFuelType'
import $ from 'jquery'

export default {
  name: 'List',
  components: {
    Empty,
    addUpdateFuelType,
  },
  data() {
    return {
      fuel_types: [],
      fuel_type: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getFuelTypes()
  },
  methods: {
    async getFuelTypes() {
      this.flag = true
      this.loading = true
      this.loader = true
      await apiClient.get('api/fuel-types')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.fuel_types = response.data.fuel_types
          setTimeout(function () {
            $('#fuel_type_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    async edit(id) {
      this.fuel_type = {}
      this.btnEdit = true
      await apiClient.get('api/fuel-types/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.fuel_type = response.data.fuel_type
        }
      })
    },
    onDelete(categoryId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/fuel-types/' + categoryId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#fuel_type_list').DataTable().destroy()
              this.getFuelTypes()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.fuel_type = {}
    },
  },
}
</script>

<style scoped>

</style>
